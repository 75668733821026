// SubmissionsPage.jsx
import React from 'react';
import { Box } from '@mui/material';
import FormSubmissions from '../components/FormSubmissions/FormSubmissions';
import styles from './SubmissionsPage.module.css';

const SubmissionsPage = ({ formType }) => {  // Accept formType as a prop
  const overrideStyle = {
    backgroundColor: '#f5f5f5', // Set the background color to light grey
    minHeight: '100vh', // Ensuring the full height of the viewport is covered
    padding: '10px', // Add padding to the page container
    boxSizing: 'border-box', // Ensures padding is included in total width and height
  };

  return (
    <div className={styles.pageContainer} style={overrideStyle}>
      <Box sx={{ p: 1 }}>
        <FormSubmissions formType={formType} /> 
      </Box>
    </div>
  );
};

export default SubmissionsPage;
