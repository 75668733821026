import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button'; // Import Button for the save functionality
import { Snackbar, Alert } from '@mui/material';
import { getLayoutConfig, getFieldMetadata, saveLayoutConfig } from '../../../utils/firestore'; // Adjust the import path as necessary
import { DropZoneTypes } from './constants'; // Ensure this path is correct
import FormLayout from './FormLayout';

const FormDesign2 = ({ formType = 'email' }) => {
    const [layoutConfig, setLayoutConfig] = useState({});
    const [fieldsMetadata, setFieldsMetadata] = useState({});
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State to hold the message
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // State to control the alert severity

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                const metadata = await getFieldMetadata();
                let layout = await getLayoutConfig(formType);
                console.log("Fetched layout config for:", formType, layout);
                console.log("Fetched fields metadata:", metadata);

                // Adjust the filter based on the formType
                const filterFields = (fieldConfig) => {
                    if (formType === 'email') {
                        return !['shortenedUrl', 'utmUrl', 'adName', 'adsetName', 'campaignName', 'landingPageUrl'].includes(fieldConfig.id);
                    } else { // for 'banner', keep only 'shortenedUrl' and 'utmUrl' fields
                        return !['shortenedUrl', 'utmUrl', 'campaignName', 'source', 'medium', 'objective', 'datePicker', 'utmYY', 'adsetName', 'date'].includes(fieldConfig.id);
                    }
                };

                Object.keys(layout).forEach(rowKey => {
                    layout[rowKey] = layout[rowKey].filter(filterFields);
                    if (layout[rowKey].length === 0) {
                        delete layout[rowKey];
                    }
                });

                const filteredMetadata = Object.keys(metadata).reduce((acc, key) => {
                    if (filterFields({ id: key })) {
                        acc[key] = metadata[key];
                    }
                    return acc;
                }, {});

                setLayoutConfig(layout || {});
                setFieldsMetadata(filteredMetadata);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [formType]);



const moveItem = (sourceRowIndex, sourceIndex, targetRowIndex, targetIndex, dropZoneType) => {
//    console.log(`Request to move item from row ${sourceRowIndex}, index ${sourceIndex} to row ${targetRowIndex}, index ${targetIndex}, dropZoneType: ${dropZoneType}`);

    const newLayoutConfig = { ...layoutConfig };
    let rowKeys = Object.keys(newLayoutConfig).sort((a, b) => parseInt(a.replace('row', '')) - parseInt(b.replace('row', '')));
//    console.log("Row keys before operation:", rowKeys);

    if (rowKeys.length === 0) {
//        console.error("No rows available in layout");
        return;
    }

    let sourceRowKey = rowKeys[sourceRowIndex];
    let targetRowKey = `row${targetRowIndex}`;

    if (dropZoneType === DropZoneTypes.LEFT || dropZoneType === DropZoneTypes.RIGHT) {
        targetRowKey = rowKeys[targetRowIndex]; // Use targetRowIndex directly for LEFT/RIGHT within the same row
        // Adjust targetIndex for LEFT/RIGHT within the same row
        if (sourceRowKey === targetRowKey) {
            if (dropZoneType === DropZoneTypes.RIGHT && targetIndex > sourceIndex) {
                targetIndex--;
            }
        }
    } else {
    if (dropZoneType === DropZoneTypes.BETWEENROWS) {
//        console.log("DropZoneType is BETWEENROWS");

        // Adjust targetRowIndex to handle BETWEENROWS logic
        targetRowIndex = targetRowIndex === -1 ? 0 : targetRowIndex + 1;

        // Calculate new row key
        targetRowKey = `row${targetRowIndex}`;
//        console.log(`Calculated targetRowKey for BETWEENROWS: ${targetRowKey}`);

        // Shift rows to make space for the new row
        for (let i = rowKeys.length - 1; i >= targetRowIndex; i--) {
            const currentRowKey = `row${i}`;
            const shiftedRowKey = `row${i + 1}`;
            newLayoutConfig[shiftedRowKey] = newLayoutConfig[currentRowKey];
//            console.log(`Shifting ${currentRowKey} to ${shiftedRowKey}`);
        }

        // Create new row
        newLayoutConfig[targetRowKey] = [];
//        console.log(`Created new row ${targetRowKey}`);

        // Adjust sourceRowKey if necessary
        if (sourceRowIndex >= targetRowIndex) {
            sourceRowKey = `row${sourceRowIndex + 1}`;
//            console.log(`Adjusting sourceRowKey to ${sourceRowKey}`);
        }

//        console.log("Layout configuration after creating new row and shifting:", JSON.stringify(newLayoutConfig));
    } 

    else if (dropZoneType === DropZoneTypes.ABOVE) {
//        console.log("DropZoneType is ABOVE");
        targetRowKey = targetRowIndex < 0 ? 'row-1' : `row${targetRowIndex}`;
    } else if (dropZoneType === DropZoneTypes.BELOW) {
//        console.log("DropZoneType is BELOW");
        targetRowKey = targetRowIndex >= rowKeys.length ? `row${rowKeys.length}` : `row${targetRowIndex + 1}`;
    } 

    }

    console.log("Target row key:", targetRowKey);

    if (!newLayoutConfig[sourceRowKey]) {
//        console.error("Invalid source row: " + sourceRowKey);
        return;
    }

    if (!newLayoutConfig[targetRowKey]) {
//        console.log(`Creating new row: ${targetRowKey}`);
        newLayoutConfig[targetRowKey] = [];
    }

//    console.log(`Moving item from ${sourceRowKey}[${sourceIndex}] to ${targetRowKey}[${targetIndex}]`);
    const [movedItem] = newLayoutConfig[sourceRowKey].splice(sourceIndex, 1);
    targetIndex = dropZoneType === DropZoneTypes.RIGHT ? targetIndex + 1 : targetIndex;
    targetIndex = Math.min(targetIndex, newLayoutConfig[targetRowKey].length);
    targetIndex = Math.max(targetIndex, 0);

    newLayoutConfig[targetRowKey].splice(targetIndex, 0, movedItem);
//    console.log("Updated layout configuration after moving item:", JSON.stringify(newLayoutConfig));
    setLayoutConfig(newLayoutConfig);
};

    // Function to handle saving the layout configuration
    const handleSave = async () => {
        try {
            await saveLayoutConfig(layoutConfig, formType);
            setSnackbarMessage('Form layout saved successfully!');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Failed to save form layout configuration.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true); // Open the snackbar with the message
    };

    // Function to close the Snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <FormLayout
                            layoutConfig={layoutConfig}
                            fieldsMetadata={fieldsMetadata}
                            moveItem={moveItem}
                        />
                        <Button onClick={handleSave} variant="contained" color="primary">
                            Save Layout
                        </Button>
                    </>
                )}
                {/* Snackbar for user feedback */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </DndProvider>
    );
};

export default FormDesign2;
