import { useState, useEffect, useCallback } from 'react';
import { getDropdownOptions, getDropdownDependencies, getLayoutConfig, getFieldMetadata } from '../../../utils/firestore'; // Make sure getFieldMetadata is imported
import generateUTM from '../UTMGenerator'; // Adjust the import path as necessary
import DataSubmissionService from '../../../services/DataSubmissionService'; // Adjust the import path as necessary

// Simple in-memory cache
let formStateCache = {
  email: { layoutConfig: null, dropdownOptions: null, dropdownDependencies: null, fieldMetadata: null },
  banner: { layoutConfig: null, dropdownOptions: null, dropdownDependencies: null, fieldMetadata: null },
};

const useFormState = (formType = 'email') => { // Add formType parameter with a default value
  const [isLoading, setIsLoading] = useState(true);
  const [layoutConfig, setLayoutConfig] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [dropdownDependencies, setDropdownDependencies] = useState({});
  const [fieldMetadata, setFieldMetadata] = useState({}); // State to hold field metadata
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [utmUrl, setUtmUrl] = useState('');
  const [validationErrors, setValidationErrors] = useState({}); // State to hold validation errors
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabledStates, setDisabledStates] = useState({}); // Add this line to manage disabled states
  const [disabledStatesInitialized, setDisabledStatesInitialized] = useState(false);
  const [dataFetchTrigger, setDataFetchTrigger] = useState(0); // New state to trigger data fetch

  const fetchData = useCallback(async () => {
    console.log('[useFormState] Fetching data for formType:', formType);
    try {
      // Corrected caching logic by using cache values correctly
      const cache = formStateCache[formType];
      const options = cache.dropdownOptions || await getDropdownOptions();
      const dependencies = cache.dropdownDependencies || await getDropdownDependencies();
      const layout = cache.layoutConfig || await getLayoutConfig(formType);
      const metadata = cache.fieldMetadata || await getFieldMetadata();

      // Update the cache with the new data
      formStateCache[formType] = {
        layoutConfig: layout,
        dropdownOptions: options,
        dropdownDependencies: dependencies,
        fieldMetadata: metadata
      };

        // Sorting logic for layoutConfig
        const sortRowKeys = (a, b) => {
          const rowNumberA = parseInt(a.replace('row', ''), 10);
          const rowNumberB = parseInt(b.replace('row', ''), 10);
          const isNegativeA = a.includes('-');
          const isNegativeB = b.includes('-');

          if (isNegativeA && !isNegativeB) return -1;
          if (!isNegativeA && isNegativeB) return 1;
          return rowNumberA - rowNumberB;
        };

        const sortedLayoutKeys = Object.keys(layout).sort(sortRowKeys);
        const sortedLayoutRows = sortedLayoutKeys.map(key => layout[key]);

        // Set state with either cached or fetched (and processed) data
      setLayoutConfig(sortedLayoutRows);
      setDropdownOptions(options);
      setDropdownDependencies(dependencies);
      setFieldMetadata(metadata);
      setFieldValues(sortedLayoutRows.reduce((acc, row) => {
        row.forEach(field => acc[field.id] = field.defaultValue || '');
        return acc;
      }, {}));
        console.log('[useFormState] Data initialized');
      } catch (error) {
        console.error('Error fetching form data:', error);
        setSnackbarMessage('Failed to fetch form data');
        setOpenSnackbar(true);
      } finally {
      setIsLoading(false);
    }
  }, [formType]); // Added formType as dependency to callback


  useEffect(() => {
    fetchData();
  }, [dataFetchTrigger, formType, fetchData]); // Added fetchData to the dependency array

  const invalidateCache = useCallback(() => {
    console.log('[useFormState] Invalidating cache for', formType);
    formStateCache[formType] = {
      layoutConfig: null,
      dropdownOptions: null,
      dropdownDependencies: null,
      fieldMetadata: null,
    };
    setDataFetchTrigger(prev => prev + 1);
  }, [formType]); // Added useCallback and formType as dependency


  useEffect(() => {
    console.log('[useFormState] formType changed, triggering cache invalidation and data re-fetch for', formType);
    invalidateCache();
  }, [formType, invalidateCache]); // Added invalidateCache to the dependency array



  // Moved the state update function for disabledStates into the useEffect
  useEffect(() => {
    if (!isLoading && fieldMetadata && fieldValues) {
      const updateDisabledStates = () => {
        const updatedDisabledStates = Object.keys(fieldMetadata).reduce((acc, fieldId) => {
          const { disabledConditions } = fieldMetadata[fieldId] || {};
          if (disabledConditions) {
            const { field, isEmpty } = disabledConditions;
            const fieldValue = fieldValues[field];
            acc[fieldId] = isEmpty ? !fieldValue : !!fieldValue;
          }
          return acc;
        }, {});

        setDisabledStates(updatedDisabledStates);
      };

      updateDisabledStates();
      if (!disabledStatesInitialized) {
        setDisabledStatesInitialized(true);
      }
    }
  }, [isLoading, fieldMetadata, fieldValues, disabledStatesInitialized]);



  const validateField = useCallback((fieldId, value) => {
    const fieldConfig = fieldMetadata[fieldId];
    return fieldConfig?.required && !value ? 'This field is required' : undefined;
  }, [fieldMetadata]);

  const validateFields = useCallback(() => {
    return layoutConfig.reduce((acc, row) => {
      row.forEach(field => {
        const error = validateField(field.id, fieldValues[field.id]);
        if (error) acc[field.id] = error;
      });
      return acc;
    }, {});
  }, [fieldValues, layoutConfig, validateField]);

  const validateForm = useCallback(() => {
    const errors = validateFields();
    setIsFormValid(Object.keys(errors).length === 0);
    setValidationErrors(errors);
  }, [validateFields]);

  useEffect(() => {
    validateForm();
  }, [fieldValues, validateForm]);

const handleFieldChange = useCallback((fieldName, value, additionalText = null) => {
//  console.log(`Handling field change for: ${fieldName}, Value: ${value}, Additional Text: ${additionalText}`);
  setFieldValues(prevValues => {
    const updatedValues = { ...prevValues, [fieldName]: value };
//    console.log(`Updated values before checking additionalText: `, updatedValues);

    if (additionalText !== null) {
      updatedValues[`${fieldName}_additional`] = additionalText;
//      console.log(`Concatenated field for ${fieldName}: ${value} ${additionalText}`);
    }
    return updatedValues;
  });
  if (validationErrors[fieldName]) {
    const updatedErrors = { ...validationErrors, [fieldName]: undefined };
//    console.log(`Updated validation errors after handling field change for ${fieldName}: `, updatedErrors);
    setValidationErrors(updatedErrors);
  }
}, [validationErrors]);


//console.log('Complete Field Values:', fieldValues);

const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    setIsSubmitting(true); // Indicate the start of form submission
//    console.log('Form submission started, isSubmitting set to true');

    // Validate all fields and collect any errors
    const errors = validateFields();
//    console.log('Validation Errors:', errors);

    // Update state with the validation errors, if any
    setValidationErrors(errors);

    // Check if there are any errors; if so, halt the submission
    if (Object.keys(errors).length > 0) {
        setIsSubmitting(false); // Reset submission status since there are errors
//        console.log('Form submission halted due to validation errors');
        return; // Stop the submission process due to validation errors
    }

//    console.log('Field values before transformations:', fieldValues); // Log field values before transformations

// Transform dropdown field values based on their respective fieldMetadata
const transformedFieldValues = { ...fieldValues };
Object.keys(transformedFieldValues).forEach(key => {
    // Check if there's additional text for the current field
    const additionalKey = `${key}_additional`;
    if (transformedFieldValues.hasOwnProperty(additionalKey)) {
        const additionalText = transformedFieldValues[additionalKey].trim();
        // Only concatenate additional text if it's not empty
        if (additionalText) {
            transformedFieldValues[key] += ` ${additionalText}`;
        }
    }

    // Apply transformations if defined
    if (fieldMetadata[key] && fieldMetadata[key].transformations) {
        transformedFieldValues[key] = applyTransformations(transformedFieldValues[key], fieldMetadata[key].transformations);
    }
});


//    console.log('Field values after transformations:', transformedFieldValues); // Log transformed field values

    try {
        // Generate UTM URL based on the transformed field values
        const generatedUtmUrl = await generateUTM(transformedFieldValues, formType);
        setUtmUrl(generatedUtmUrl); // Update the UTM URL in the state

//        console.log('Generated UTM URL:', generatedUtmUrl);

        // Prepare the form data for submission, including the generated UTM URL
        const formData = {
            ...transformedFieldValues,
            utmUrl: generatedUtmUrl,
        };

        console.log('Final form data being submitted:', formData); // This will show the final data being submitted

        // Submit the form data to your Firestore or desired backend service
        await DataSubmissionService.submitToFirestore(formData);
        setSnackbarMessage('Name created successfully');
        setOpenSnackbar(true);
        setValidationErrors({});
    } catch (error) {
        console.error('Error during submission:', error);
        setSnackbarMessage('Error during submission');
        setOpenSnackbar(true);
    } finally {
        setIsSubmitting(false); // Reset submission status regardless of the outcome
//        console.log('Form submission process completed, isSubmitting reset to false');
    }
};

// Utility function to apply transformations to a field value based on the specified rules
const applyTransformations = (value, transformations) => {
    let transformedValue = value;

    // Capitalize transformations
    switch (transformations.capitalize) {
        case 'first-letter':
            transformedValue = transformedValue.charAt(0).toUpperCase() + transformedValue.slice(1).toLowerCase();
            break;
        case 'all-caps':
            transformedValue = transformedValue.toUpperCase();
            break;
        case 'no-caps':
            transformedValue = transformedValue.toLowerCase();
            break;
        case 'no restrictions':
            // No action needed, retain original capitalization
            break;
        default:
            // No action if no matching case, could also be 'no restrictions' or undefined
            break;
    }

    // Spacing transformations
    switch (transformations.spacing) {
        case 'underscore':
            transformedValue = transformedValue.replace(/\s+/g, '_');
            break;
        case 'hyphen':
            transformedValue = transformedValue.replace(/\s+/g, '-');
            break;
        case 'no-spacing':
            transformedValue = transformedValue.replace(/\s/g, '');
            break;
        case 'no restrictions':
            // No action needed, retain original spacing
            break;
        default:
            // No action if no matching case, could also be 'no restrictions' or undefined
            break;
    }

    // Symbols allowance
    if (!transformations.allowSymbols) {
        transformedValue = transformedValue.replace(/[^\w\s_]/gi, ''); // Keep allowed spacing symbols if any
    }

    // URL Encoding
    if (transformations.encode) {
        transformedValue = encodeURIComponent(transformedValue);
    }

    return transformedValue;
};

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  return {
    isLoading,
    layoutConfig,
    fieldValues,
    dropdownOptions,
    dropdownDependencies,
    fieldMetadata, 
    handleFieldChange,
    handleSubmit,
    openSnackbar,
    snackbarMessage,
    handleCloseSnackbar,
    showSnackbar,
    utmUrl,
    setFieldValues,
    invalidateCache,
    isFormValid, // Expose form validity status
    validationErrors, // Make validation errors available to form fields
    isSubmitting, 
    disabledStates,
    disabledStatesInitialized,
  };
};

export default useFormState;