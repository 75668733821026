import React from 'react';
import FormDesign2 from '../components/FormSettings/FormDesign/FormDesign2';

// Add a prop to receive formType
const FormLayout = ({ formType }) => {
    const title = formType === 'email' ? 'Email Form Layout Settings' : 'Banner Form Layout Settings';

    return (
        <div style={{ margin: '20px' }}>
            <h1>{title}</h1>
            <p>Use the interface below to rearrange the form fields as needed.</p>
            {/* Pass formType to FormDesign2 */}
            <FormDesign2 formType={formType} />
        </div>
    );
};

export default FormLayout;
