import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Default icon
import { format, isValid } from 'date-fns';

const DateFieldComponent = ({
  fieldId,
  fieldMetadata,
  formValues,
  onChange,
  isSubmitting = false,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (fieldMetadata.disabledConditions) {
      const { field, isEmpty } = fieldMetadata.disabledConditions;
      const relatedFieldValue = formValues[field];
      setIsDisabled(isEmpty ? !relatedFieldValue : false);
    }
  }, [fieldMetadata.disabledConditions, formValues]);

const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    if (newValue && isValid(newValue)) {
        // First format the date to get the month in uppercase
        let formattedDate = format(newValue, 'MMMddyyyy');

        // Convert the month part to lowercase
        formattedDate = formattedDate.replace(/^[A-Z]+/, match => match.toLowerCase());

        // Prepend the underscore
        formattedDate = '_' + formattedDate;

        // Send the formatted date to the onChange handler
        onChange(fieldId, formattedDate);
    } else {
        onChange(fieldId, '');
    }
};


  const handleClear = () => {
    setSelectedDate(null);
    onChange(fieldId, '');
  };

//  console.log(`Rendering DateFieldComponent for fieldId: ${fieldId}`);
//  console.log(`Selected date: ${selectedDate}`);
//  console.log(`Disabled state: ${isDisabled}`);
//  console.log(`Full width applied: ${TextField.props?.fullWidth}`);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={fieldMetadata.label}
        value={selectedDate}
        onChange={handleDateChange}
        disabled={isDisabled || isSubmitting}
        clearable
        showTodayButton
        todayText="Today"
        components={{
          OpenPickerIcon: selectedDate ? ClearIcon : CalendarTodayIcon, // Use CalendarTodayIcon as default
        }}
        onOpen={() => selectedDate && handleClear()} // Clear the date when trying to open the picker if a date is already selected
        renderInput={(params) => (
          <TextField
            {...params}
            label={fieldMetadata.label}
            required={fieldMetadata.required}
            error={fieldMetadata.externalError || false}
            helperText={fieldMetadata.externalHelperText || fieldMetadata.helperText || ''}
            disabled={isDisabled || isSubmitting}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {selectedDate && (
                    <IconButton onClick={handleClear} size="small">
                      <ClearIcon />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

DateFieldComponent.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fieldMetadata: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    externalError: PropTypes.bool,
    externalHelperText: PropTypes.string,
    helperText: PropTypes.string,
    disabledConditions: PropTypes.shape({
      field: PropTypes.string,
      isEmpty: PropTypes.bool,
    }),
  }).isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default DateFieldComponent;
