import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import TextFieldComponent from '../Fields/TextFieldComponent';
import DropdownFieldComponent from '../Fields/DropdownFieldComponent';
import URLFieldComponent from '../Fields/URLFieldComponent';
import DateFieldComponent from '../Fields/DateFieldComponent';

const FormField = memo(({
  fieldConfig,
  value,
  onFieldChange,
  onDropdownChange,
  allFieldValues,
  validationErrors,
  fieldMetadata,
  isSubmitting,
  disabledStates, 
}) => {
//  console.log(`[FormField] Render - Field ID: ${fieldConfig.id}, Type: ${fieldConfig.type}, Disabled: ${disabledStates[fieldConfig.id]}, Submitting: ${isSubmitting}`);

  const {
    id,
    type,
    options,
    dynamicOptions,
    includeOther,
    disabledConditions
  } = fieldConfig;

  // Extract metadata for the current field or use defaults from fieldConfig
  const {
    label = fieldConfig.label,
    hint = fieldConfig.hint,
    helperText = fieldConfig.helperText,
    required = fieldConfig.required,
    maxLength = fieldConfig.maxLength,
    externalError = false,
    externalHelperText = '',
  } = fieldMetadata || {};

  // Optimized useCallback to prevent unnecessary re-creation of the function
  const isFieldDisabled = useCallback(() => {
    const disabled = !!disabledStates[id] || isSubmitting;
//    console.log(`[FormField - ${id}] Disabled state: ${disabled}`);
    return disabled;
  }, [id, disabledStates, isSubmitting]);

const handleTextChange = useCallback((fieldId, newValue) => {
  // Directly use newValue since it's not an event object in this context
//    console.log(`[FormField - ${fieldId}] Handle text change: ${newValue}`);
  onFieldChange(fieldId, newValue);
}, [onFieldChange]);


  const isError = validationErrors && validationErrors[id];
  const finalHelperText = isError ? validationErrors[id] : (externalError ? externalHelperText : helperText);
//  console.log(`[FormField - ${id}] Error check: ${isError}, Final Helper Text: ${finalHelperText}`);

  const isValidMetadata = type === 'dropdown' && fieldMetadata && typeof fieldMetadata === 'object';

  switch (type) {
    case 'text':
      return (
        <TextFieldComponent
          fieldId={id}
          fieldMetadata={fieldMetadata}
          value={value || ''}
          onChange={handleTextChange}
          disabled={isFieldDisabled()}
          helperText={finalHelperText}
          required={required}
          maxLength={maxLength}
          isSubmitting={isSubmitting}
        />
      );

    case 'url':
      if (!fieldMetadata || Object.keys(fieldMetadata).length === 0) {
        console.error(`FormField - No metadata found for field ${id}`, fieldMetadata);
      }
      return (
        <URLFieldComponent
          fieldId={id}
          fieldMetadata={fieldMetadata}
          value={value || ''}
          onChange={(newValue) => onFieldChange(id, newValue)}
          disabled={isFieldDisabled()}
          helperText={finalHelperText}
          required={required}
        />
      );
    case 'datePicker': // Handle the 'date' type
      return (
        <DateFieldComponent
          fieldId={id}
          fieldMetadata={fieldMetadata}
          formValues={allFieldValues} // Pass all form values required for any conditional logic within the DateFieldComponent
          onChange={handleTextChange} // Use the existing text change handler or create a specific one if needed
          isSubmitting={isSubmitting}
          disabled={isFieldDisabled()} // Use the existing logic to determine if the field is disabled
        />
      );
      
    case 'dropdown':
      if (!isValidMetadata) {
        console.error(`FormField - Invalid configuration for dropdown field ${id}.`);
        return <div>Error: Invalid configuration for dropdown field.</div>;
      }
      //console.log(`FormField - Rendering Dropdown for ${id} with value:`, value);
      //console.log(`FormField - Dropdown Options for ${id}:`, dynamicOptions || options);

      return (
        <DropdownFieldComponent
          fieldId={id}
          fieldMetadata={fieldMetadata}
          options={dynamicOptions || options || []}
          value={value ? { label: value } : null}
          onChange={(selectedOption) => {
            onDropdownChange(id, selectedOption);
          }}
          disabled={isFieldDisabled() || isSubmitting} // Consider form submission state
          includeOther={includeOther}
          helperText={finalHelperText}
          required={required}
        />
      );

    default:
      console.warn(`Unsupported field type '${type}' encountered for field '${id}'.`);
      return null;
  }
}, (prevProps, nextProps) => {
  // Efficiently comparing nextProps with prevProps to avoid unnecessary re-renders
  const propsEqual = prevProps.value === nextProps.value &&
    prevProps.isSubmitting === nextProps.isSubmitting &&
    prevProps.disabledStates[nextProps.fieldConfig.id] === nextProps.disabledStates[nextProps.fieldConfig.id] &&
    deepEqual(prevProps.allFieldValues, nextProps.allFieldValues) &&
    deepEqual(prevProps.validationErrors, nextProps.validationErrors) &&
    deepEqual(prevProps.fieldMetadata, nextProps.fieldMetadata);

  if (!propsEqual) {
 //   console.log(`[FormField - ${nextProps.fieldConfig.id}] Prop changes detected, component will re-render.`);
  }

  return propsEqual;
});

// Utility function for deep equality check to optimize the memo comparison
function deepEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

FormField.propTypes = {
  fieldConfig: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.array,
    hint: PropTypes.string,
    dynamicOptions: PropTypes.array,
    includeOther: PropTypes.bool,
    maxLength: PropTypes.number,
    disabledConditions: PropTypes.object,
  }).isRequired,
  value: PropTypes.any,
  onFieldChange: PropTypes.func.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  allFieldValues: PropTypes.object.isRequired,
  validationErrors: PropTypes.object,
  fieldMetadata: PropTypes.object,
  isSubmitting: PropTypes.bool, // Add this line
  disabledStates: PropTypes.object, // Include disabledStates in propTypes
};

export default FormField;
