import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import theme from './styles/theme';
import NavbarComponent from './components/Navbar/NavbarComponent';
import FooterComponent from './components/Footer/FooterComponent';
import DropdownManagement from './pages/DropdownManagementPage';
import SubmissionsPage from './pages/SubmissionsPage'; 
import SubmitSettings from './pages/SubmitSettings';
import FormLayout from './pages/FormLayout';
import FormBuilderPage from './pages/FormBuilderPage';
import HomePage2 from './pages/Home2';
import UTMBanner from './pages/UTMBanner';



// Custom hook for tracking page views
const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);
};

const RouteChangeTracker = () => {
  usePageTracking();
  return null;
};

const App = () => {
  const isInIframe = () => {
    return window.location !== window.parent.location;
  };

return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RouteChangeTracker />
        {!isInIframe() && <NavbarComponent />}
        <Routes>
          <Route path="/" element={<HomePage2 />} />
          <Route path="/utmbanner" element={<UTMBanner />} />
          <Route path="/manage-dropdowns" element={<DropdownManagement />} />
          <Route path="/submissions" element={<SubmissionsPage />} />
  <Route path="/submissions/email" element={<SubmissionsPage formType="email" />} />
  <Route path="/submissions/banner" element={<SubmissionsPage formType="banner" />} />

          <Route path="/formsettings/submit/email" element={<SubmitSettings formType="email" />} />
          <Route path="/formsettings/submit/banner" element={<SubmitSettings formType="banner" />} />
          <Route path="/formsettings/layout/email" element={<FormLayout formType="email" />} />
          <Route path="/formsettings/layout/banner" element={<FormLayout formType="banner" />} />
          <Route path="/form-builder" element={<FormBuilderPage />} />

          {/* Add other routes as needed */}
        </Routes>
        {!isInIframe() && <FooterComponent />}
      </Router>
    </ThemeProvider>
  );
};

export default App;
