// components/Form/FormHeader.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Typography, Avatar } from '@mui/material';

const FormHeader = ({ title, subheader, avatarSrc, avatarSize }) => {
    return (
        <CardHeader
            avatar={
                avatarSrc && (
                    <Avatar
                        alt={title}
                        src={avatarSrc}
                        sx={{
                            width: avatarSize.width, 
                            height: avatarSize.height, 
                            borderRadius: '8px', // This will make the avatar fully rounded
                            //border: '1px solid', // Adjust the border thickness as needed
                            //borderColor: '#B5A076', // Soft gold color, change as needed for Fairmont brand
                            padding: '5px', // Padding between the border and the logo, adjust as needed
                            marginRight: '10px',
                        }}
                    />
                )
            }
            title={
                <Typography variant="h4" sx={{ color: '#333333' }}>
                    {title}
                </Typography>
            }
            subheader={
                <Typography variant="body1" sx={{ color: '#FF0000' }}>
                    {subheader}
                </Typography>
            }
        />
    );
};

FormHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    avatarSrc: PropTypes.string,
    avatarSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
};

FormHeader.defaultProps = {
    subheader: '',
    avatarSrc: '',
    avatarSize: { width: 200, height: 102 }, // Default size based on your usage
};

export default FormHeader;
