import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCymiWBY078Ed17M8Nj3jkOR89HteA6hTQ",
  authDomain: "conanew318.firebaseapp.com",
  projectId: "conanew318",
  storageBucket: "conanew318.appspot.com",
  messagingSenderId: "871467709230",
  appId: "1:871467709230:web:69fdb03d8f31ed196f00bf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

//console.log("Firestore instance (db):", db); // Console log the Firestore instance

export { db };