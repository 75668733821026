import React, { useState, useEffect } from 'react';
//import { useParams } from 'react-router-dom';
import { TextField, Chip, Stack, Button, Paper, Typography, IconButton, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getFieldMetadata, getNamingStructure, saveNamingStructure } from '../../utils/firestore';

const NameGenerator = ({ formType }) => {  // Accept formType as a prop
  const [elements, setElements] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [fieldOptions, setFieldOptions] = useState([]);
  const [isEdited, setIsEdited] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  console.log("Resolved formType:", formType); // Log the resolved formType used in the component

  useEffect(() => {
    const initialize = async () => {
      console.log("Initializing with formType:", formType); // Log which formType is being initialized
      const fieldMetadata = await getFieldMetadata();
      let loadedStructure = await getNamingStructure(formType);

      if (!loadedStructure || loadedStructure === '') {
        loadedStructure = Object.keys(fieldMetadata).map(key => `{{${fieldMetadata[key].label}}}`).join(' ');
        await saveNamingStructure(loadedStructure, formType);
      }

      const processedElements = processStructureIntoElements(loadedStructure);
      setElements(processedElements);

      const options = Object.keys(fieldMetadata).map(key => fieldMetadata[key].label);
      setFieldOptions(options || []);
    };

    initialize();
  }, [formType]);

  const processStructureIntoElements = (structure) => {
    const regex = /{{(.*?)}}|(\S+)/g;
    let match;
    const processedElements = [];

    while ((match = regex.exec(structure)) !== null) {
      if (match[1]) {
        processedElements.push({ type: 'chip', value: match[1] });
      } else if (match[2]) {
        processedElements.push({ type: 'text', value: match[2] });
      }
    }
    return processedElements;
  };

  const handleInputChange = (event) => {
    setCurrentInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddElement();
    }
  };

  const handleAddElement = () => {
    if (currentInput.trim() !== '') {
      setElements([...elements, { type: 'text', value: currentInput }]);
      setCurrentInput('');
      setIsEdited(true);
    }
  };

  const handleAddChip = (chipLabel) => {
    setElements([...elements, { type: 'chip', value: chipLabel }]);
    setIsEdited(true);
  };

  const handleDeleteElement = (index) => {
    setElements(elements.filter((_, i) => i !== index));
    setIsEdited(true);
  };

const handleSave = () => {
  // Process each element to determine how it should be added to the structure
  const structure = elements.map((el, index) => {
    if (el.type === 'chip') {
      // For chip types (fields), return the value wrapped in {{}}
      return `{{${el.value}}}`;
    } else {
      // For text elements, check if they should be merged with adjacent chips without spaces
      const prevIsChip = index > 0 && elements[index - 1].type === 'chip';
      const nextIsChip = index < elements.length - 1 && elements[index + 1].type === 'chip';
      // Trim the text element if it's between chips or if it's the first/last element in the array
      if (prevIsChip || nextIsChip || index === 0 || index === elements.length - 1) {
        return el.value.trim();
      }
      // Return the text element as is, including any explicit spaces
      return el.value;
    }
  }).join(''); // Join all elements into a single string without adding spaces between them

  saveNamingStructure(structure, formType)
    .then(() => {
      setSnackbarMessage('Structure saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setIsEdited(false); // Reset isEdited to false after saving
    })
    .catch(error => {
      console.error('Error saving structure:', error);
      setSnackbarMessage('Error saving structure');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    });
};


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }}>
      {/* Field Options Section */}
      <Typography variant="h6" gutterBottom>
        Select from the Available Form Fields for {formType.toUpperCase()}
      </Typography>
      <Paper style={{ padding: '10px', marginBottom: '20px' }}>
        {fieldOptions.map((option, index) => (
          <Chip
            key={index}
            label={option}
            onClick={() => handleAddChip(option)}
            variant="outlined"
            style={{ margin: '5px' }}
          />
        ))}
      </Paper>

      {/* Text Input Section */}
      <Typography variant="h6" gutterBottom>
        Or add your own Text
      </Typography>
      <TextField
        label="Type text or add field"
        value={currentInput}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        fullWidth
        margin="dense"
        onBlur={handleAddElement}
      />

      {/* Preview Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }} sx={{ fontWeight: 'bold' }}>
        Your Structure for {formType.toUpperCase()}
      </Typography>
      <Paper style={{ padding: '10px', minHeight: '50px', overflow: 'hidden' }}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ flexWrap: 'wrap' }}>
          {elements.map((element, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              {element.type === 'chip' ? (
                <Chip label={`{{${element.value}}}`} color="primary" />
              ) : (
                <span>{element.value}</span>
              )}
              <IconButton
                size="small"
                onClick={() => handleDeleteElement(index)}
                sx={{
                  marginLeft: 1,
                  color: 'blue',
                  backgroundColor: 'lightblue',
                  '&:hover': {
                    backgroundColor: 'darkblue',
                    color: 'white'
                  },
                  fontSize: '0.75rem'
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          ))}
        </Stack>
      </Paper>

      {/* Save Button */}
      <Button variant="contained" onClick={handleSave} style={{ marginTop: '20px' }} disabled={!isEdited}>
        Save Structure
      </Button>

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NameGenerator;
