import { useState, useEffect, useCallback } from 'react';
import { subscribeToDropdownOptions, subscribeToDropdownDependencies, getDropdownOptions, getDropdownDependencies } from '../../../utils/firestore';

const useDropdownOptions = () => {
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [dropdownDependencies, setDropdownDependencies] = useState({});
  const [fullOptions, setFullOptions] = useState({}); // Added to cache full options

  useEffect(() => {
    let isMounted = true; // Track if component is mounted

    const fetchData = async () => {
      try {
        const [options, dependencies] = await Promise.all([
          getDropdownOptions(),
          getDropdownDependencies(),
        ]);

        if (isMounted) {
          setDropdownOptions(options);
          setDropdownDependencies(dependencies);
          setFullOptions(options); // Cache the full options initially fetched
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchData();

    const optionsUnsubscribe = subscribeToDropdownOptions(newOptions => {
      if (isMounted) {
        setDropdownOptions(newOptions);
        setFullOptions(newOptions); // Update the cache when new options are subscribed to
      }
    });

    const dependenciesUnsubscribe = subscribeToDropdownDependencies(newDependencies => {
      if (isMounted) setDropdownDependencies(newDependencies);
    });

    return () => {
      isMounted = false;
      optionsUnsubscribe();
      dependenciesUnsubscribe();
    };
  }, []);

  const updateOptionsBasedOnDependencies = useCallback((fieldName, labelValue) => {
    setDropdownOptions(currentOptions => {
      const updatedOptions = { ...currentOptions };

      if (dropdownDependencies.dynamicDependencies?.[labelValue]) {
        const dependencies = dropdownDependencies.dynamicDependencies[labelValue];
        Object.keys(dependencies).forEach(depField => {
          updatedOptions[depField] = dependencies[depField];
        });
      }

      // Reset other fields to full options if no dependencies for the new 'source' selection
      if (fieldName === 'source') {
        Object.keys(fullOptions).forEach(field => {
          if (!dropdownDependencies.dynamicDependencies?.[labelValue]?.[field]) {
            updatedOptions[field] = fullOptions[field] || [];
          }
        });
      }

      return updatedOptions;
    });
  }, [dropdownDependencies, fullOptions]);

  return {
    dropdownOptions,
    dropdownDependencies,
    updateOptionsBasedOnDependencies,
  };
};

export default useDropdownOptions;
