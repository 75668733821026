import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { Paper, TextField, InputAdornment } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import LinkIcon from '@mui/icons-material/Link';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import EventIcon from '@mui/icons-material/Event'; // Importing EventIcon for date picker fields
import DropZone from './DropZone';
import { DropZoneTypes } from './constants';

const DraggableItem = ({ id, label, type, required, index, rowIndex, moveItem, rowKeys }) => {
    const ref = useRef(null);
    const [, drag, preview] = useDrag(() => ({
        type: 'field',
        item: { id, index, rowIndex },
    }));

    drag(ref);

    const handleDrop = (item, dropZoneType, targetRowIndex, targetIndex) => {
        console.log(`Dropped: ${item.id} from row ${item.rowIndex}, index ${item.index} to row ${targetRowIndex}, index ${targetIndex}, dropZoneType: ${dropZoneType}`);
        moveItem(item.rowIndex, item.index, targetRowIndex, targetIndex, dropZoneType);
    };

    const getTypeIcon = () => {
        switch (type) {
            case 'text':
                return <TextFieldsIcon />;
            case 'url':
                return <LinkIcon />;
            case 'dropdown':
                return <ArrowDropDownCircleIcon />;
            case 'datePicker': // Adding a case for 'date' type fields
                return <EventIcon />;
            // Add more cases for other types as needed
            default:
                return null; // No icon for unknown types
        }
    };

    return (
        <div ref={preview} style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
            <DropZone onDrop={handleDrop} type={DropZoneTypes.LEFT} rowIndex={rowIndex} fieldIndex={index - 1} rowKeys={rowKeys} />
            <Paper ref={ref} style={{ margin: '8px', padding: '10px', flexGrow: 1 }}>
                <TextField
                    fullWidth
                    label={label}
                    required={required}
                    variant="outlined"
                    disabled // Disable the field to prevent interaction within the builder UI
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {getTypeIcon()}
                            </InputAdornment>
                        ),
                    }}
                />
            </Paper>
            <DropZone onDrop={handleDrop} type={DropZoneTypes.RIGHT} rowIndex={rowIndex} fieldIndex={index + 1} rowKeys={rowKeys} />
        </div>
    );
};

export default DraggableItem;
