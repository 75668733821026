// components/Form/FormActions.jsx
import React from 'react';
import { Button, Box } from '@mui/material';
import UTMViewer from './UTMViewer';
import styles from './FormComponent2.module.css';

const FormActions = ({ handleSubmit, utmUrl, setShortenedUrl, isFormValid, formType }) => { // Added formType prop
    return (
        <>
            <Box sx={{ textAlign: "center", mt: 0 }}>
            <Button 
                variant="contained" 
                sx={{
                    backgroundColor: "#F40000",
                    width: "30%",
                    height: "50px",
                    boxShadow: "none",
                    my: 0,
                    '&:hover': {
                        backgroundColor: '#E60000', // Darker shade of red for hover
                        // Reset on touch devices, it is recommended to remove the hover effect on touch devices
                        '@media (hover: none)': {
                            backgroundColor: '#FF0000',
                        },
                    }
                }}
                onClick={handleSubmit}
                disabled={!isFormValid}
            >
                GET CAMPAIGN NAME
            </Button>
            </Box>
            
            {/* Apply the utmViewerWrapper class only to this Box that wraps UTMViewer */}
            <Box className={styles.utmViewerWrapper}>
                <UTMViewer utm={utmUrl} onShortenedUrl={setShortenedUrl} formType={formType} />
            </Box>
        </>
    );
};

export default FormActions;
