import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper } from '@mui/material';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import CustomTable from '../CustomTable/CustomTable';

const FormSubmissions = ({ formType }) => {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'submittedAt', direction: 'desc' });
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setLoading(true);
    const fetchMetadataAndSubmissions = async () => {
      try {
        const metadataCollectionRef = collection(db, 'fieldMetadata');
        const metadataSnapshot = await getDocs(metadataCollectionRef);
        const metadata = {};
        metadataSnapshot.forEach(doc => {
          metadata[doc.id] = doc.data().label;
        });

        const columnOrder = ['submittedAt', 'source', 'medium', 'objective', 'date', 'datePicker', 'landingPageUrl', 'utmsource', 'utmmedium', 'utmbrand', 'utmcategory', 'utmdescription', 'utmobjective', 'adName', 'utmUrl'];
        let dynamicColumns = columnOrder.map(columnId => ({
          id: columnId,
          label: metadata[columnId] ? metadata[columnId] : columnId,
          isVisible: true,
          sortable: true
        }));

        const responseCollectionRef = collection(db, 'formSubmissions');
        const responseSnapshot = await getDocs(responseCollectionRef);
        const combinedSubmissions = responseSnapshot.docs.map(doc => {
          const data = doc.data();
          const formattedSubmission = {
            submittedAt: data.submittedAt ? data.submittedAt.toDate().toLocaleString() : 'N/A',
          };
          for (const key in data.responses) {
            formattedSubmission[key] = data.responses[key].value;
          }
          return formattedSubmission;
        });
        console.log('Fetched data:', combinedSubmissions);

        setColumns(dynamicColumns);
        setSubmissions(combinedSubmissions);
        // Initially set filteredSubmissions to combinedSubmissions
        filterAndSortData(combinedSubmissions, sortConfig, selectedDateRange);
      } catch (fetchError) {
        console.error('Error fetching data:', fetchError);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchMetadataAndSubmissions();
  }, []);

// Function to filter and sort data
const filterAndSortData = (data, sortConfig, dateRange, searchTerm) => {
  let filteredData = data;

  if (dateRange.start && dateRange.end) {
    const startDate = new Date(dateRange.start);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(dateRange.end);
    endDate.setHours(23, 59, 59, 999);

    filteredData = filteredData.filter(submission => {
      const submissionDate = new Date(submission.submittedAt);
      return submissionDate >= startDate && submissionDate <= endDate;
    });
  }

  if (sortConfig.key) {
    filteredData.sort((a, b) => {
      let valA = a[sortConfig.key];
      let valB = b[sortConfig.key];

      // If sorting by submittedAt, ensure comparison is done with Date objects
      if (sortConfig.key === 'submittedAt') {
        valA = new Date(valA);
        valB = new Date(valB);
      }

      if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1;
      if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }

  setFilteredSubmissions(filteredData);
};


  // Update handleSort to use filterAndSortData
  const handleSort = (columnId) => {
    const isAsc = sortConfig.key === columnId && sortConfig.direction === 'asc';
    const newSortConfig = { key: columnId, direction: isAsc ? 'desc' : 'asc' };
    setSortConfig(newSortConfig);
    filterAndSortData(submissions, newSortConfig, selectedDateRange);
  };

  // Define handleDateRangeChange function
  const handleDateRangeChange = (newRange) => {
    setSelectedDateRange({ start: newRange[0], end: newRange[1] });
    filterAndSortData(submissions, sortConfig, { start: newRange[0], end: newRange[1] });
  };

  // Call filterAndSortData when date range changes
  useEffect(() => {
    console.log('Date range changed, re-filtering and sorting data');

    filterAndSortData(submissions, sortConfig, selectedDateRange);
  }, [selectedDateRange, submissions, sortConfig]);

const handleSearch = (newSearchTerm) => {
  setSearchTerm(newSearchTerm);
  filterAndSortData(submissions, sortConfig, selectedDateRange, newSearchTerm);
};

  const handleColumnVisibilityChange = (columnId) => {
    setColumns(columns.map(column => {
      if (column.id === columnId) {
        return { ...column, isVisible: !column.isVisible };
      }
      return column;
    }));
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const isDataReady = columns.length > 0 && submissions.length > 0;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {isDataReady ? (
        <CustomTable 
          data={filteredSubmissions}
          columns={columns}
          onSort={handleSort}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          onDateRangeChange={handleDateRangeChange} // Pass handleDateRangeChange correctly as a prop
          onSearch={handleSearch}
        />
      ) : (
        <p>Loading table data...</p>
      )}
    </Paper>
  );
};

export default FormSubmissions;