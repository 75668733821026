import React, { useState, useEffect } from 'react';
import { getNamingStructure, saveNamingStructure } from '../utils/firestore';
import NameGenerator from '../components/FormSettings/NameGenerator'; 

const SubmitSettings = ({ formType }) => {  // Access formType directly from props
  const [currentStructure, setCurrentStructure] = useState('');

  console.log('SubmitSettings formType:', formType); // Log the formType on every render


  useEffect(() => {

    console.log('SubmitSettings useEffect triggered for formType:', formType); // Log when useEffect is triggered

    const loadNamingStructure = async () => {
      console.log('Fetching naming structure for formType:', formType); // Log the fetch attempt

      // Fetch the naming structure based on the formType
      const structure = await getNamingStructure(formType);
      console.log('Received naming structure for formType:', formType, structure); // Log the fetched structure

      setCurrentStructure(structure);
    };

    loadNamingStructure();
  }, [formType]); // Re-run the effect when formType changes

  const handleSaveStructure = (newStructure) => {
    console.log('Attempting to save structure for formType:', formType); // Log save attempt

    // Save the naming structure specific to the formType
    saveNamingStructure(newStructure, formType)
      .then(() => {
        console.log('Structure saved successfully for formType:', formType); // Log successful save
        // Optionally update the state or provide user feedback
      })
      .catch(error => {
        console.error('Error saving structure for formType:', formType, error); // Log any errors
        // Handle errors, e.g., show a notification to the user
      });
  };

  return (
    <div>
      <h1>Form Submit Settings for {formType}</h1>
      <NameGenerator
        currentStructure={currentStructure}
        onSaveStructure={handleSaveStructure}
        formType={formType}
      />
    </div>
  );
};

export default SubmitSettings;
