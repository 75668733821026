import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, FormControl, InputAdornment, IconButton, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CustomFieldWrapper from './CustomFieldWrapper';
import AdditionalTextInput from './AdditionalTextInput'; 

const OptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '& > span': {
    fontSize: '0.875rem',
  },
}));

const HintText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const DropdownFieldComponent = ({
  fieldId, 
  fieldMetadata, 
  options,
  dynamicOptions, 
  value,
  onChange,
  submitted,
  disabled = false, 
  externalHelperText = '',
  isSubmitting = false,
}) => {

  const {
    label,
    hint,
    helperText,
    required,
    includeOther,
    maxLength,
  } = fieldMetadata;

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [additionalText, setAdditionalText] = useState(''); // State to store additional text
  const [isMaxLengthReached, setIsMaxLengthReached] = useState(false); // State to track if max length is reached

  useEffect(() => {


    // Ensure dynamicOptions and options are arrays to prevent undefined errors
    const safeDynamicOptions = dynamicOptions || [];
    const safeOptions = options || [];

    // Check if the current value is in the new dynamic options
    const valueInDynamicOptions = safeDynamicOptions.some(option => option.label === value?.label);

    // If dynamicOptions are provided and the current value is not among them, reset
    if (safeDynamicOptions.length > 0 && !valueInDynamicOptions && value?.label !== 'Other') {
      console.log(`[useEffect] Resetting input value due to incompatible value`);
      setInputValue('');
      onChange(null);
    } else if (safeDynamicOptions.length === 0) {
      // If there are no dynamic options, it might mean we've switched to an option without dependencies
      // In this case, maintain the current value if it's part of the general options
      const valueInGeneralOptions = safeOptions.some(option => option.label === value?.label);
      if (!valueInGeneralOptions && value?.label !== 'Other') {
        console.log(`[useEffect] Resetting input value due to value not in general options`);
        setInputValue('');
        onChange(null);
      }
    } else {
      setInputValue(value?.label || '');
    }
  }, [dynamicOptions, options, onChange, value, fieldId]);

  useEffect(() => {
    if (inputValue === 'Other' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputValue]);

  const handleAutocompleteChange = (_, newValue) => {
    console.log(`[handleAutocompleteChange] New value:`, newValue);

    if (newValue?.label === 'Other') {
      console.log(`[handleAutocompleteChange] 'Other' selected, prompting for input`);
      setInputValue('Other');
      setAdditionalText('');
      onChange({ label: 'Other', additionalText: '' });
    } else {
      console.log(`[handleAutocompleteChange] Option selected:`, newValue);
      setInputValue(newValue?.label || '');
      setAdditionalText('');
      onChange(newValue);
    }
  };

  const actualOptions = Array.isArray(dynamicOptions) ? dynamicOptions : Array.isArray(options) ? options : [];
  const dropdownOptions = includeOther ? [...actualOptions, { label: 'Other' }] : actualOptions;
  console.log(`[Dropdown Options]`, dropdownOptions);

  const selectedOption = dropdownOptions.find(option => option.label === value?.label) || null;
  console.log(`[Selected Option]`, selectedOption);

  const applyTransformations = (inputValue) => {
    console.log(`[applyTransformations] Initial inputValue:`, inputValue);

    const { capitalize, spacing, allowSymbols } = fieldMetadata.transformations || {};

    switch (capitalize) {
      case 'first-letter':
        inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
        break;
      case 'all-caps':
        inputValue = inputValue.toUpperCase();
        break;
      case 'no-caps':
        inputValue = inputValue.toLowerCase();
        break;
      case 'no restrictions':
      default:
        break;
    }

    switch (spacing) {
      case 'underscore':
        inputValue = inputValue.replace(/\s+/g, '_');
        break;
      case 'hyphen':
        inputValue = inputValue.replace(/\s+/g, '-');
        break;
      case 'no-spacing':
        inputValue = inputValue.replace(/\s+/g, '');
        break;
      case 'no restrictions':
      default:
        break;
    }

    if (!allowSymbols) {
      inputValue = inputValue.replace(/[^\w\s_]/gi, '');
    }

    return inputValue;
  };

  const handleTextFieldChange = (event) => {
    const formattedValue = applyTransformations(event.target.value);
    const isMaxLenReached = formattedValue.length > maxLength;
    const finalText = isMaxLenReached ? formattedValue.substring(0, maxLength) : formattedValue;

    setIsMaxLengthReached(isMaxLenReached);
    setAdditionalText(finalText);
    onChange({ label: 'Other', additionalText: finalText });
  };


  const handleAdditionalTextChange = (text) => {
    setAdditionalText(text);
    console.log(`[handleAdditionalTextChange] Additional text:`, text);
    onChange({ label: value?.label, additionalText: text });
  };

  const handleBlur = () => {
    console.log(`[handleBlur] Input value:`, inputValue);

    if (inputValue === 'Other' && !additionalText) {
      setInputValue('');
    }
  };

  const handleClear = () => {
    console.log(`[handleClear] Clearing input value`);
    setInputValue('');
    setAdditionalText('');
    onChange(null);
  };

  const isValidValue = actualOptions.some(option => option.label === value?.label) || (includeOther && value?.label);
  const showError = submitted && required && !isValidValue;
  const formattedLabel = required ? `${label} *` : label;

  const renderCustomInput = (params) => {
    const inputProps = params.InputProps || {};

    return (
      <CustomFieldWrapper
        {...params}
        label={formattedLabel}
        placeholder={hint || ''}
        helperText={showError ? 'This field is required' : helperText}
        error={showError}
        variant="outlined"
        disabled={disabled || isSubmitting}
        InputProps={{
          ...inputProps,
          endAdornment: (
            <>
              {value?.label && (
                <IconButton onClick={handleClear} size="small">
                  <ClearIcon />
                </IconButton>
              )}
              {inputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  return (
    <FormControl fullWidth required={required} error={showError}>
      {inputValue === 'Other' ? (
        <CustomFieldWrapper
          label={formattedLabel}
          value={additionalText}
          onChange={handleTextFieldChange}
          onBlur={handleBlur}
          helperText={isMaxLengthReached ? 'Maximum character limit reached.' : helperText}
          error={isMaxLengthReached}
          variant="outlined"
          disabled={disabled || isSubmitting}
          placeholder="Type your answer"
          maxLength={maxLength}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {inputValue === 'Other' && (
                  <IconButton edge="end" onClick={handleClear} size="small">
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          autoFocus={inputValue === 'Other'}
        />
      ) : (
      <>
        <Autocomplete
          options={dropdownOptions}
          value={selectedOption}
          onChange={handleAutocompleteChange}
          renderInput={renderCustomInput}
          renderOption={(props, option) => (
            <li {...props}>
              <OptionContainer>
                <span>{option.label}</span>
                {option.hint && <HintText>{option.hint}</HintText>}
              </OptionContainer>
            </li>
          )}
          disableClearable
          autoHighlight
          autoSelect
          fullWidth
          isOptionEqualToValue={(option, val) => option.label === val?.label}
          disabled={disabled}
        />
        <AdditionalTextInput
          allowAdditionalText={fieldMetadata.allowAdditionalText}
          selectedValue={value?.label}
          onAdditionalTextChange={handleAdditionalTextChange}
          transformations={fieldMetadata.transformations}
          disabled={disabled || isSubmitting}
          maxLength={fieldMetadata.maxLength}
        />
      </>
      )}
    </FormControl>
  );
};

DropdownFieldComponent.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fieldMetadata: PropTypes.shape({
    label: PropTypes.string,
    hint: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    includeOther: PropTypes.bool,
    maxLength: PropTypes.number,
    transformations: PropTypes.object,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      hint: PropTypes.string,
    })
  ),
  dynamicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      hint: PropTypes.string,
    })
  ),
  value: PropTypes.shape({
    label: PropTypes.string,
    hint: PropTypes.string,
    additionalText: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  disabled: PropTypes.bool,
  externalHelperText: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default DropdownFieldComponent;
