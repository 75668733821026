import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Slide, FormHelperText } from '@mui/material';

const AdditionalTextInput = ({
  allowAdditionalText,
  selectedValue,
  onAdditionalTextChange,
  transformations,
  disabled,
  maxLength
}) => {
  const [additionalText, setAdditionalText] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isMaxLengthReached, setIsMaxLengthReached] = useState(false); // New state to track max length

  useEffect(() => {
    // Clear additional text if the selected dropdown value changes
    setAdditionalText('');
  }, [selectedValue]);

  useEffect(() => {
    // Set visibility based on whether additional text is allowed and a selection is made
    setIsVisible(selectedValue && allowAdditionalText);
  }, [selectedValue, allowAdditionalText]);

const handleTextChange = (event) => {
    let text = event.target.value;
    
    // First, apply transformations
    const transformedText = applyTransformations(text, transformations);

    // Then check and apply maxLength if defined and needed
    const isMaxLenReached = typeof maxLength === 'number' && transformedText.length >= maxLength;

    const finalText = isMaxLenReached
        ? transformedText.substring(0, maxLength)
        : transformedText;

    // Update state for max length reached
    setIsMaxLengthReached(isMaxLenReached);

    // Set the text and propagate changes
    setAdditionalText(finalText);
    onAdditionalTextChange(finalText);
};


  const applyTransformations = (text, { capitalize, spacing, allowSymbols }) => {
    let transformedText = text;

    // Apply capitalization transformations
    switch (capitalize) {
      case 'first-letter':
        transformedText = transformedText.charAt(0).toUpperCase() + transformedText.slice(1).toLowerCase();
        break;
      case 'all-caps':
        transformedText = transformedText.toUpperCase();
        break;
      case 'no-caps':
        transformedText = transformedText.toLowerCase();
        break;
      // Default case is no transformation
    }

    // Apply spacing transformations
    switch (spacing) {
      case 'underscore':
        transformedText = transformedText.replace(/\s+/g, '_');
        break;
      case 'hyphen':
        transformedText = transformedText.replace(/\s+/g, '-');
        break;
      case 'no spacing':
        transformedText = transformedText.replace(/\s+/g, '');
        break;
      // Default case is no transformation
    }

    // Remove symbols if not allowed
    if (!allowSymbols) {
      transformedText = transformedText.replace(/[^\w\s-]/gi, '');
    }

    return transformedText;
  };

  if (!allowAdditionalText) return null;

  return (
    <div style={{
      maxHeight: isVisible ? '100px' : '0', // Adjust maxHeight according to the TextField's size
      overflow: 'hidden',
      transition: 'max-height 0.3s ease-out',
      marginLeft: '25px', // Indentation to suggest hierarchy
      marginTop: '2px', // Reduced top margin for closer proximity to the dropdown field
      paddingTop: '5px', // Add some padding at the top to ensure the floating label has enough space
    }}>
      <TextField
        label="(optional) Additional Information"
        value={additionalText}
        onChange={handleTextChange}
        fullWidth
        variant="outlined"
        disabled={disabled}
        size="small" // Set the TextField size to 'small'
        error={isMaxLengthReached} // Use the error prop to signal a problem
        inputProps={{
          maxLength // HTML input maxLength attribute to prevent entering more characters
        }}
      />
      {isMaxLengthReached && (
        <FormHelperText error={true}>
          Maximum character limit reached.
        </FormHelperText>
      )}      
    </div>
  );
};

AdditionalTextInput.propTypes = {
  allowAdditionalText: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  onAdditionalTextChange: PropTypes.func.isRequired,
  transformations: PropTypes.object,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number
};

export default AdditionalTextInput;
