import React, { useState, useCallback } from 'react';
import { Card, CardContent, Typography, Box, Tabs, Tab } from '@mui/material';
import FormFieldsContainer from './FormFieldsContainer';
import useFormState from './hooks/useFormState';
import useDropdownOptions from './hooks/useDropdownOptions';
import FormFeedback from './FormFeedback';
import FormHeader from './FormHeader';
import FormActions from './FormActions';
import styles from './FormComponent2.module.css';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import UTMViewer from './UTMViewer';
//import BannerForm from './BannerForm'; // Ensure this component is defined


const FormComponent2 = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const formType = selectedTab === 0 ? 'email' : 'banner'; // Determine form type based on selected tab

  const {
    isLoading,
    layoutConfig,
    fieldValues,
    handleFieldChange,
    handleSubmit, // Destructure handleSubmit from useFormState
    utmUrl,
    openSnackbar,
    snackbarMessage,
    handleCloseSnackbar,
    fieldMetadata, // Destructure fieldMetadata from useFormState
    isFormValid, // Assuming this is part of the state managed by useFormState
    disabledStates, // Destructure disabledStates here
    disabledStatesInitialized, // Add this line to destructure disabledStatesInitialized from useFormState
  } = useFormState(formType); // Pass formType to useFormState

  const {
    dropdownOptions,
    dropdownDependencies,
    updateOptionsBasedOnDependencies,
  } = useDropdownOptions();

//  console.log('[FormComponent2] Rendering');
//  console.log('[FormComponent2] useFormState values:', { isLoading, layoutConfig, fieldValues, utmUrl, openSnackbar, snackbarMessage, fieldMetadata, isFormValid, disabledStates, disabledStatesInitialized });
//  console.log('[FormComponent2] useDropdownOptions values:', { dropdownOptions, dropdownDependencies });


  const handleDropdownChange = useCallback((fieldName, newValue) => {
  const labelValue = newValue ? newValue.label : '';
  const additionalText = newValue ? newValue.additionalText || '' : '';

//  console.log(`[FormComponent2] handleDropdownChange invoked for field "${fieldName}" with value:`, labelValue, 'Additional Text:', additionalText);

  // If there is additionalText, it should be appended to the main value
  handleFieldChange(fieldName, labelValue, additionalText);

  // Update dependencies using just the labelValue, ignoring additionalText for dependency logic
  updateOptionsBasedOnDependencies(fieldName, labelValue);
  }, [handleFieldChange, updateOptionsBasedOnDependencies]);



  if (isLoading) {
    return <div>Loading...</div>;
  }

//  console.log('[FormComponent2] Preparing to render FormFieldsContainer and other children');

  return (
    <Card className={styles.formComponent} sx={{ width: '90%', margin: '0 auto', mt: 1 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '0 10px' }}>
        <FormHeader
          title="myCoke SFMC"
          subheader="Email Name & Banner UTM Generator"
          avatarSrc="/MyCoke_1080x1080.png"
          avatarSize={{ width: 195, height: 104 }}
        />
      <Tabs 
        value={selectedTab} 
        onChange={(e, newValue) => setSelectedTab(newValue)} 
        aria-label="form type tabs" 
        sx={{ 
          marginX: 'auto', 
          marginTop: '-30px', 
          marginBottom: '15px',
          '& .MuiTabs-indicator': {
            backgroundColor: '#FF0000',
          },
          '& .MuiTab-root': {
            color: '#777', // Default color
            fontWeight: 'normal',
            marginRight: '20px', // Increase spacing between tabs
          },
          '& .MuiTab-root.Mui-selected': { // Increase specificity
            color: '#FF0000', // Selected tab color
            fontWeight: 'bold', // Selected tab font weight
          }
        }}
      >
        <Tab label="Email" />
        <Tab label="Banner" />
      </Tabs>
      </div>
      <Typography variant="caption" sx={{ color: 'gray', textAlign: 'center', mt: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mb: 0 }}>
        <span style={{ fontWeight: 'bold' }}>Form settings:</span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <KeyboardCapslockIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} /> 
          no caps allowed,
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <SpaceBarIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} />
          spaces as "_"
        </span>
      </Typography>
      <CardContent>
            <FormFieldsContainer
              key={formType}              
              isLoading={isLoading}
              disabledStatesInitialized={disabledStatesInitialized}
              layoutConfig={layoutConfig}
              fieldValues={fieldValues}
              handleFieldChange={handleFieldChange}
              handleDropdownChange={handleDropdownChange}
              dropdownOptions={dropdownOptions}
              dropdownDependencies={dropdownDependencies}
              fieldMetadata={fieldMetadata}
              disabledStates={disabledStates}
            />
          <FormActions 
            handleSubmit={handleSubmit} 
            utmUrl={utmUrl} 
            isFormValid={isFormValid} 
            formType={formType} // Make sure formType is defined and passed here
          />
            <FormFeedback open={openSnackbar} message={snackbarMessage} onClose={handleCloseSnackbar} />
      </CardContent>
    </Card>
  );
};

export default FormComponent2;
