import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const CustomFieldWrapperBase = forwardRef(({
  autoFocus,
  variant,
  customStyles,
  size,
  focusColor,
  disabled,
  error,
  options, // For Autocomplete
  renderInput, // For custom input rendering logic
  inputRef, // To forward the ref
  InputProps, // To pass down InputProps
  placeholder, // To handle placeholder for hints
  maxLength,
  ...props
}, ref) => {
  const theme = useTheme();

  const variantStyles = {
    ...getVariantStyles(variant, theme, customStyles, focusColor, disabled, error),
  };

  // Define isAutocomplete based on the presence of options and renderInput
  const isAutocomplete = options && renderInput;

  if (isAutocomplete) {
    return (
      <Autocomplete
        {...props}
        options={options}
        disabled={disabled}
        renderInput={(params) => renderInput({
          ...params,
          ref: inputRef,
          size,
          variant,
          disabled,
          error,
          sx: { ...variantStyles, ...props.sx },
          InputProps: {
            ...params.InputProps,
            ...InputProps,
            style: {
              ...params.InputProps?.style,
              ...InputProps?.style,
              backgroundColor: disabled ? 'rgba(0, 0, 0, 0.06)' : undefined,
            },
            // Ensure inputProps are correctly passed and merged with maxLength
            inputProps: { ...params.InputProps?.inputProps, maxLength: maxLength }
          },
          placeholder,
        })}
      />
    );
  } else {
    return (
      <TextField
        {...props}
        ref={ref}
        variant={variant}
        size={size}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        maxLength={maxLength}
        sx={{ ...variantStyles, ...props.sx }}
        InputProps={{
          ...InputProps,
          style: {
            ...InputProps?.style,
            // Apply the background color conditionally based on disabled state
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.06)' : undefined,
          },
        }}
        autoFocus={autoFocus}
      />
    );
  }
});



const CustomFieldWrapper = styled(CustomFieldWrapperBase)(({ theme }) => ({
  width: '100%',
  // Additional base styles if needed
}));

CustomFieldWrapper.propTypes = {
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  customStyles: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
  focusColor: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  options: PropTypes.array, // For Autocomplete
  renderInput: PropTypes.func, // For custom input rendering logic
  inputRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.any })
  ]), // To forward the ref
  InputProps: PropTypes.object, // To pass down InputProps
  placeholder: PropTypes.string, // To handle placeholder for hints
  maxLength: PropTypes.number, // Include maxLength in PropTypes
};

function getVariantStyles(variant, theme, customStyles, focusColor, disabled, error) {
  const baseStyles = {
    '& .Mui-disabled': {
      color: theme.palette.text.disabled,
      '& .MuiOutlinedInput-notchedOutline, & .MuiFilledInput-underline:before, & .MuiInput-underline:before': {
        borderColor: theme.palette.action.disabledBackground,
      },
    },
    ...(error && {
      color: theme.palette.error.main,
      '& .MuiOutlinedInput-notchedOutline, & .MuiFilledInput-underline:before, & .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderColor: theme.palette.error.main,
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.error.main,
      },
    }),
    // Add focus styles here
  };

  // Add variant-specific styles
  switch (variant) {
    case 'outlined':
      return {
        ...baseStyles,
        '& .MuiOutlinedInput-root': {
          borderColor: focusColor || theme.palette.primary.main,
          ...customStyles,
        },
      };
    case 'filled':
      return {
        ...baseStyles,
        '& .MuiFilledInput-root': {
          backgroundColor: theme.palette.grey[100],
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.grey[100],
          },
          ...customStyles,
        },
      };
    case 'standard':
      return {
        ...baseStyles,
        '& .MuiInput-root': {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${focusColor || theme.palette.primary.main}`,
          },
          ...customStyles,
        },
      };
    default:
      return {};
  }
}

export default CustomFieldWrapper;