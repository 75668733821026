import React, { useState, useEffect, useRef } from 'react';
import {
  Table, TableBody, TableContainer, Paper, IconButton, ListItemText,
  TableRow, TableCell, Box, Tooltip, Button, Popover, Chip, TextField, List, ListItem, ListItemIcon, Switch, TablePagination
} from '@mui/material';
import {
  Search as SearchIcon, Event as EventIcon, ViewColumn as ViewColumnIcon
} from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomTableHeader from './CustomTableHeader';
import CustomTableRow from './CustomTableRow';
import ExportOptions from '../ExportOptions/ExportOptions';


const CustomTable = ({ data, columns, onSort, onColumnVisibilityChange, onDateRangeChange, onSearch }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerms, setSearchTerms] = useState([]);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState('Today');
  const [customRange, setCustomRange] = useState([new Date(), new Date()]);
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const searchInputRef = useRef(null);
  const [currentSortColumn, setCurrentSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

  const getRowStyle = (rowIndex) => ({
    height: 24, // Adjust height as needed
    backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#f5f5f5', // Zebra striping colors
  });

  const dateRanges = {
    'Today': [new Date(), new Date()],
    'Yesterday': [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
    'Last 7 Days': [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
    // ... other predefined ranges
  };

  useEffect(() => {
    console.log('CustomTable: Data received for table:', data);
    filterDataByDateRange();
  }, [data, customRange, isCustomRange]);


  // Function to filter data based on selected date range
  const filterDataByDateRange = () => {
//  console.log('CustomTable: Filtering data by date range...');
//  console.log('CustomTable: Current customRange:', customRange);

    const [start, end] = customRange;
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);

//  console.log('CustomTable: Calculated startDate:', startDate);
//  console.log('CustomTable: Calculated endDate:', endDate);

    const filtered = data.filter(row => {
      // Convert submittedAt to a Date object if it's a string or timestamp
      const submittedDate = row.submittedAt instanceof Date ? row.submittedAt : new Date(row.submittedAt);
      const isInDateRange = submittedDate >= startDate && submittedDate <= endDate;
//      console.log(`CustomTable: Checking row with submittedAt: ${submittedDate.toISOString()}, isInDateRange: ${isInDateRange}`);
      return isInDateRange;
    });

//    console.log(`CustomTable: Data filtered by date range. Original count: ${data.length}, Filtered count: ${filtered.length}`);
    setFilteredData(filtered);
  };


    const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchTerm.trim() !== '') {
     onSearch(searchTerm.trim());  // Trigger search in parent component
      setSearchTerms(prevTerms => [...prevTerms, searchTerm.trim()]);
      setSearchTerm('');
      setPage(0); // Reset pagination on new search
      event.preventDefault();
      setTimeout(() => searchInputRef.current?.focus(), 0);
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setSearchTerms(chips => chips.filter(chip => chip !== chipToDelete));
  };

  const filterData = () => {
    if (searchTerms.length === 0) {
      setFilteredData(data);
      return;
    }

    const filtered = data.filter(row =>
      searchTerms.some(term =>
        Object.values(row).some(value =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      )
    );

    setFilteredData(filtered);
    setPage(0); // Reset pagination on new search
  };

  useEffect(filterData, [searchTerms, data]);

  const handleDateRangeMenu = (event) => {
    setDateRangeAnchorEl(event.currentTarget);
  };

  const handleDateRangeClose = () => {
    setDateRangeAnchorEl(null);
  };

  const selectPredefinedRange = (rangeKey) => {
    const newRange = dateRanges[rangeKey];
    setSelectedDateRange(rangeKey); // Update local state if needed for display
    setCustomRange(newRange);
    setIsCustomRange(false);
    onDateRangeChange(newRange); // Notify parent component about the date range change
    handleDateRangeClose();
  };

// When custom date range changes
const handleCustomDateChange = (date, index) => {
  const newRange = [...customRange];
  newRange[index] = date;
  setCustomRange(newRange);
  if (newRange[0] && newRange[1]) {
    setIsCustomRange(true);
    onDateRangeChange(newRange); // Notify parent component about the date range change
  }
};

  const handleSort = (columnId) => {
    console.log(`CustomTable: Sorting data by column ${columnId}`);
    const isAsc = currentSortColumn === columnId && sortDirection === 'asc';
  console.log(`CustomTable: Current sort direction before toggle: ${sortDirection}`);
    
    setSortDirection(isAsc ? 'desc' : 'asc');
  console.log(`CustomTable: New sort direction after toggle: ${isAsc ? 'desc' : 'asc'}`);

    setCurrentSortColumn(columnId);
    onSort(columnId, isAsc ? 'desc' : 'asc'); // Assuming onSort needs columnId and direction
  };
  
  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : '...';
  };

  const displayDateRange = () => {
    if (isCustomRange) {
      return `${formatDate(customRange[0])} - ${formatDate(customRange[1])}`;
    }
    return selectedDateRange;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleColumnVisibilityChange = (columnId) => {
    onColumnVisibilityChange(columnId);
  };



  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', mb: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px', gap: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Button startIcon={<EventIcon />} onClick={handleDateRangeMenu}>
            {displayDateRange()}
          </Button>
          <Popover
            open={Boolean(dateRangeAnchorEl)}
            anchorEl={dateRangeAnchorEl}
            onClose={handleDateRangeClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box p={2}>
              {Object.keys(dateRanges).map((rangeKey) => (
                <Button key={rangeKey} onClick={() => selectPredefinedRange(rangeKey)}>{rangeKey}</Button>
              ))}
              <Box my={2}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={customRange[0]}
                  onChange={(date) => handleCustomDateChange(date, 0)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="MM/dd/yyyy"
                  value={customRange[1]}
                  onChange={(date) => handleCustomDateChange(date, 1)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Box>
          </Popover>
        </LocalizationProvider>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', padding: '2px 4px' }}>
          <IconButton size="small" sx={{ marginRight: '8px' }}>
            <SearchIcon />
          </IconButton>
          {searchTerms.map((term, index) => (
            <Chip
              key={index}
              label={term}
              onDelete={() => handleDeleteChip(term)}
              color="primary"
              size="small"
              sx={{ marginRight: '8px' }}
            />
          ))}
		<input
		  ref={searchInputRef}
		  type="text"
		  value={searchTerm}
		  onChange={handleSearchInput}
		  onKeyPress={handleKeyPress}
		  style={{ border: 'none', outline: 'none', flexGrow: 1 }}
		  placeholder="Search..."
		  id="searchInput"  // Add this line
		  name="searchInput" // Add this line
		/>

        </Box>
        <Tooltip title="Column Visibility">
          <IconButton size="small" onClick={handleMenuClick}>
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItem>
              <ListItemText primary="View Columns" />
            </ListItem>
            {columns.map((column) => (
              <ListItem key={column.id} button onClick={() => handleColumnVisibilityChange(column.id)}>
                <ListItemIcon>
                  <Switch edge="end" checked={column.isVisible} />
                </ListItemIcon>
                <ListItemText primary={column.label} />
              </ListItem>
            ))}
          </List>
        </Popover>
		<Tooltip title="Export">
		  <div>
		    <ExportOptions data={filteredData} columns={columns.filter(col => col.isVisible)} />
		  </div>
		</Tooltip>
      </Box>
      <TableContainer>
        <Table>
          <CustomTableHeader 
            columns={columns.filter(col => col.isVisible)} 
            onSort={handleSort} 
            currentSortColumn={currentSortColumn}
            sortDirection={sortDirection}
          />
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                // Log the row data before passing it to CustomTableRow
//                console.log(`CustomTable: Rendering row #${index + page * rowsPerPage}`, row);

                // Return the CustomTableRow component
                return (
                  <CustomTableRow
                    key={index}
                    row={row}
                    columns={columns.filter(col => col.isVisible)}
                    index={index + page * rowsPerPage}
                  />
                );
              })}
            {emptyRows > 0 && (
              [...Array(emptyRows)].map((_, emptyRowIndex) => (
                <TableRow
                  key={`empty-${emptyRowIndex}`}
                  style={getRowStyle(emptyRowIndex + filteredData.length)}
                >
                  <TableCell colSpan={columns.length} />
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredData.length} // Use filteredData for count
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CustomTable;
